@import "../../global.scss";

.contact {
  background-color: white;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .left {
    flex: 1;
    overflow: hidden;
    background: linear-gradient(to right, #74ebd5, #acb6e5);

    img {
      height: 100%;

      @include mobile {
        display: flex;
        text-align: center;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 30px;
    }

    form {
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      input {
        width: 300px;
        height: 30px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 20px;
        }
      }

      textarea {
        width: 300px;
        height: 200px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 100px;
        }
      }

      button {
        width: 150px;
        height: 30px;
        color: white;
        background-color: black;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      span {
        color: green;
      }
    }
  }
}

.contact-details{
  margin-top: 30px;
}

.linkedin{
  display: flex;
margin-top: 10px;
margin-bottom: 10px;
  h4{
    margin-left: 10px;
  }
}