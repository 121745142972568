
.menu{
  width:300px;
  height:100vh;
  background-color: black;
  position:fixed;
  top:0;
  right: -300px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
 
  &.active{
    right:0;
  }
 
  img{
   margin-top: 40px;
   height: 100px;
   width: 100px;
   border-radius: 50%;
   margin-bottom: 10px;
   }
 
   h1{
     color: white;
     font-size: 20px;
     margin-top: 5px;
     margin-bottom: 20px;
   }
 
  .skills{
    margin-bottom: 0;
 
   ul{
     margin-top: 0;
     padding:0;
     margin-left: 10px;
     margin-right:200px;
     margin-bottom: 10px;
     list-style: none;
     font-size: 10px;
     position: relative;
 
     li {
       font-size: 15px;
       font-weight: 400;
       margin-top: 10px;
       margin-bottom: 0;
     }
   }
  }
 
  ul{
    margin-top: 30px;
    padding:0;
    list-style: none;
    font-size: 20px;
    font-weight: 300;
    color:white;
    width:60%;
 
    li{
      margin-bottom: 20px;
 
      a{
        font-size:inherit;
        color: inherit;
        text-decoration: inherit;
      }
      &:hover{
        font-weight: 500;
        color: blueviolet;
      }
    }
  }
 } 
 